/* Note this needs the var exhibitorType to be set either to 'sponsor' or 'exhibitor'
 */

export default {
  data() {
    return {
      numFavorites: 0,
    }
  },

  created() {
    this.getFavorites()
  },

  methods: {
    getFavorites() {
      const favorites = this.$storage.getItem(`favorite_${this.exhibitorType}`, [])
      this.numFavorites = favorites.length
      return favorites
    },

    toggleFavorites(onOff) {
      if (onOff) {
        this.$set(this.filter, 'favorites', "1")
      } else {
        this.$delete(this.filter, 'favorites')
      }
    },
  },
}