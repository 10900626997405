import { render, staticRenderFns } from "./exhibitor-list-mixin.vue?vue&type=template&id=927b2a30&scoped=true"
import script from "./exhibitor-list-mixin.vue?vue&type=script&lang=js"
export * from "./exhibitor-list-mixin.vue?vue&type=script&lang=js"
import style0 from "./exhibitor-list-mixin.vue?vue&type=style&index=0&id=927b2a30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../zpcard/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "927b2a30",
  null
  
)

export default component.exports