var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-timetable-wrapper",staticStyle:{"margin-bottom":"5rem"}},[(_vm.toastMessage)?_c('toast',{attrs:{"message":_vm.toastMessage},on:{"closed":function($event){_vm.toastMessage=''}}}):_vm._e(),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","padding-bottom":"15px"}},[_c('div',{staticClass:"flex-shrink-0 c-location-wrapper",staticStyle:{"border-right":"3px dashed black","max-width":"15vw"}},_vm._l((_vm.locations),function(loc){return _c('div',{key:loc.id,staticClass:"overflow-hidden",style:({
                paddingTop: '2rem',
                paddingRight: '1rem',
                width: 'auto',
                height: _vm.timelineEleHeight + 'px',
               })},[_c('div',{staticClass:"font-weight-bold d-flex align-items-center",staticStyle:{"height":"100%","font-size":"90%"}},[_vm._v(" "+_vm._s(loc.name)+" ")])])}),0),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","column-gap":".5rem","overflow-x":"scroll","padding-bottom":"15px"}},_vm._l((_vm.getDays),function(value,key,index){return _c('div',{key:index,staticStyle:{"padding-left":"25px","height":"100%"},style:({'border-left': index !== 0 ? '3px dashed black' : ''})},[_c('div',{staticClass:"mb-2"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.getDayInterval(key)),function(int,i){return _c('div',{style:({width: _vm.gridGapSizePx + 'px', overflow: 'hidden'})},[_vm._v(" "+_vm._s(int.getMinutes() === 0 ? int.getHours()+'Uhr' : '')+" ")])}),0)]),_c('div',{style:(_vm.calGridStyle(key, value.length))},_vm._l((value),function(event,i){return _c('div',{key:event.id,staticClass:"timeline-element",class:_vm.cssSettings(event),style:(_vm.calTimelineEleStyle(event, key, i)),attrs:{"title":event.title},on:{"click":function($event){return _vm.toggleModal(event)}}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.displayTime(event.start_datetime))+"-"+_vm._s(_vm.displayTime(event.end_datetime)))]),_c('div',{staticClass:"font-weight-bold",style:({overflow: 'hidden',
              '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
                    'text-overflow': 'ellipsis'})},[_vm._v(_vm._s(event.title))]),_c('div',{staticClass:"small",staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(event.speakers.flatMap(obj => obj.first_name+' '+obj.last_name).join(', '))+" ")])])}),0)])}),0)]),(_vm.showModal)?_c('div',{staticStyle:{"z-index":"999","left":"0","top":"0","width":"100%","height":"100%","position":"fixed","background":"rgba(0,0,0, 0.75)","visibility":"hidden"},style:({visibility: _vm.showModal ? 'unset' : undefined})},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"100%"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.modalEvent.location.name)+" Session")]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":_vm.toggleModal}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('talk-detail',{attrs:{"talk":_vm.modalEvent,"language-display":false},on:{"favorite-changed":function($event){return _vm.onFavoriteChanged($event)},"stream":function($event){return _vm.$emit('stream', $event)},"speaker-click":_vm.toggleModal}})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.toggleModal}},[_vm._v("OK")])])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }