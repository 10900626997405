import Vue from 'vue'

export function FavoriteStorage(api, mattermostApi, cacheActive=true) {
    let self = this
    self.user = {}
    self.websocket = null
    self.favorite_keys = ['favorite_session', 'favorite_speaker', 'favorite_exhibitor',
                          'favorite_sponsor']

    self.observed = Vue.observable({ 
      numDirectUnreadMessages: 0,
      contactIds: [],
      directChannels: []
    })

    self.setObserved = function(key, value) {
      if (self.observed.hasOwnProperty(key))
        self.observed[key] = value
      else
        throw(`unknown observable: ${key}`)
    }

    self.getToken = function() {
      return localStorage.getItem('speaker_tool_token')
    }

    self.getMattermostToken = function() {
      return self.get('mm_token') || mattermostApi.defaults.guestToken
    }

    self.get = function(key, Default=null) {
      let item = localStorage.getItem(key)
      return item ? item : Default
    }

    self.getItem = function(key, Default=null) {
      let item = localStorage.getItem(key)
      return item ? JSON.parse(item) : Default
    }

    self.setItem = function(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    }

    self.set = function(key, value) {
      localStorage.setItem(key, value)
    }

    self.getFavorites = function() {
      let favorites = {}
      for (let k of self.favorite_keys) {
        let values = localStorage.getItem(k)
        favorites[k] = values ? JSON.parse(values) : []
      }
      return favorites
    }

    self.fetchUser = function() {
      if (self.getToken() && navigator.onLine) {
          return api.get(`user-profile/${self.getToken()}/`).then((response) => {
              self.user = response.data
              self.initStorage()
              localStorage.setItem('speaker_tool_user', JSON.stringify(self.user))
              return response
          }).catch(error_response => {
            self.user = self.getItem('speaker_tool_user', {})
            console.error(error_response)
            throw error_response
          })
      }
      self.user = self.getItem('speaker_tool_user', {})
      self.initStorage()
      return Promise.resolve({data: self.user})
    }

    self.initStorage = function() {
      console.log('initStorage()')
      let must_update = false
      let remote_favorites = {}
      for (let k of self.favorite_keys) {
        let values = localStorage.getItem(k) ? JSON.parse(localStorage.getItem(k)) : null
        let remote_values = self.user?.customer?.favorites[k]
        if (values && !remote_values) {
          remote_favorites[k] = values
          must_update = true
        } else if (remote_values) {
          localStorage.setItem(k, JSON.stringify(remote_values))
          values = remote_values
        }
        if (cacheActive)
          self.cacheFavorites(k, values)
      }
      if (must_update && self.getToken() && navigator.onLine)
        self.updateRemoteFavorites(remote_favorites)
    }

    self.cacheFavorites = function(key, values) {
      // caches (overwrites) the local storage favorite objs for offline usage
      key = key.slice(9) + 's'
      if (!values || !values.length) {
        localStorage.removeItem(key)
        return
      }

      let pk = ['sessions', 'speakers'].indexOf(key) >= 0 ? 'content_code' : 'sfid'
      let params = {}
      params[pk] = values
      api.get(`/${key}/`, {params: params}).then(response => {
        let objs = {}
        for (let obj of response.data.results) {
            objs[obj[pk]] = obj
        }
        localStorage.setItem(key, JSON.stringify(objs))
      })
    }

    self.updateRemoteFavorites = function(favorites) {
      let customer = {favorites: favorites}
      api.patch(`user-profile/${self.getToken()}/`, {customer: customer}).then((response) => {
        self.user = response.data
      })
    }

    self.updateRemoteFavoriteKey = function(key, values) {
      let favorites = {}
      for (let k of self.favorite_keys) {
        let vals = localStorage.getItem(k) ? JSON.parse(localStorage.getItem(k)) : null
        if (vals)
            favorites[k] = vals
      }
      favorites[key] = values

      api.patch(`user-profile/${self.getToken()}/`, {customer: {favorites: favorites}}).then((response) => {
        self.user = response.data
      })
    }

    self.fetchContactsForUser = function(mattermostUserId) {
      console.log('fetchContactsForUser')

      function getContactIdFromChannelName(name) {
        // name e.g. "37du74susib1uf334ssboonedh__g6wi9e36g7ympbfkm1xtzub6ah"
        let id = name.replace(mattermostUserId, '')
        return id.replace('__', '')
      }

      return mattermostApi.get(`/users/${mattermostUserId}/channels`).then(response => {
        self.observed.directChannels = response.data.filter(channel => channel.type == 'D') || []
        self.setItem('direct-channels', self.observed.directChannels)
        self.observed.contactIds = self.observed.directChannels.map(channel =>
          getContactIdFromChannelName(channel.name))
        self.setItem('contact-ids', self.observed.contactIds)
        response.data.directChannels = self.observed.directChannels
        response.data.contactIds = self.observed.contactIds

        // make the feedback channel messages all read
        let feedbackChannel = response.data.filter(channel => channel.header.startsWith('Feedbackbot')) || null;
        if (feedbackChannel) self.viewFeedbackChannel(mattermostUserId, feedbackChannel.id)
        return response
      })
    }

    self.viewFeedbackChannel = function(mattermostUserId, feedbackChannelId) {
      let payload = {channel_id: feedbackChannelId}
      return mattermostApi.post(`/channels/members/${mattermostUserId}/view`, payload)
    }

    self.getDirectChannel = function(contactId) {
      return self.observed.directChannels.filter(channel => channel.name.includes(contactId))[0]
    }

    self.getDirectUnreads = function() {
      // fetch all contacts (private message channels) of current user and their unread msg.
      let mmUserId = self.get('mm_user_id')
      if (mmUserId && navigator.onLine) {
        self.observed.numDirectUnreadMessages = 0
        self.fetchContactsForUser(mmUserId).then(response => {
          // collect the unread of each direct channel
          for (const c of self.observed.directChannels) {
            let url = `/users/me/channels/${c.id}/unread`
            mattermostApi.get(url).then(response => {
              c.numUnreadMessages = response.data.msg_count || 0
              self.observed.numDirectUnreadMessages += c.numUnreadMessages
            })
          }
        })
      } else if (mmUserId) {
        self.observed.directChannels = self.getItem('direct-channels', [])
        self.observed.contactIds = self.getItem('contact-ids', [])
      } else {
        self.observed.directChannels = []
        self.observed.contactIds = []
        self.observed.numDirectUnreadMessages = 0
      }
    }

    self.markChannelAsRead = function(channelId) {
      let userId = self.get('mm_user_id')
      if (userId) {
        mattermostApi.post(`/channels/members/${userId}/view`, {channel_id: channelId}).then(response => {
          let channel = self.observed.directChannels.filter(channel => channel.id == channelId)[0]
          if (channel) {
            self.observed.numDirectUnreadMessages -= channel.numUnreadMessages
            channel.numUnreadMessages = 0
          }
        })
      }
    }

    self.connectWebSocket = function() {
      console.log('url: ', mattermostApi.defaults.webSocketUrl)
      try {
        self.websocket = new WebSocket(mattermostApi.defaults.webSocketUrl)
      } catch(error) {
        self.websocket = null
        return
      }

      self.websocket.onopen = () => {
        console.log('WebSocket connection established.');
        self.connected = true;

        // Authentifizierung mit Ihren Benutzeranmeldeinformationen oder einem API-Token
        self.websocket.send(JSON.stringify({
          'seq': 1,
          'action': 'authentication_challenge',
          'data': {
            'token': self.getMattermostToken()
          }
        }));
      };

      let mmUserId = self.get('mm_user_id')
      if (mmUserId) {
        self.websocket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          // if (message && message.event) console.log('** storage handler: ', message)
          if (message.event === 'posted') {
            let data = message.data
            //if (data.channel_type === 'D' && data.channel_name.includes(mmUserId)) {
            if (data.channel_type === 'D') {
              self.getDirectUnreads()
            }
          }
        }
      }

      self.websocket.onclose = () => {
        console.log('WebSocket connection closed.');
        self.connected = false;
      };
    }

    self.disConnectWebSocket = function() {
      if (self.websocket) {
        self.websocket.close()
        self.websocket = null
      }
    }

    self.initMattermost = function() {
      if (navigator.onLine) {
        self.connectWebSocket()
        self.getDirectUnreads()
      }
    }

    self.performLogout = function() {
      localStorage.removeItem('speaker_tool_token')
      localStorage.removeItem('speaker_tool_user')
      localStorage.removeItem('mm_token')
      localStorage.removeItem('mm_user_id')
      self.disConnectWebSocket()
      self.initMattermost()
    }

    self.performLogin = function(data) {
      localStorage.setItem('speaker_tool_token', data.token)
      localStorage.setItem('mm_token', data.mm_token)
      localStorage.setItem('mm_user_id', data.mm_user_id)
      self.disConnectWebSocket()
      self.initMattermost()
    }

    self.askForPushNotificationPermission = function() {
      if ('Notification' in window && 'serviceWorker' in navigator) {
        if (Notification.permission === 'default') {
          // default means, not granted and not denied, no ask so far.
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              self.subscribeUserToPush()
            } else {
              alert('You will not be able to receive private messages from community members, when app is inactive. You can change this via System settings notifications.')
              self.set('pushNotification', 'denied')
            }
          })
        } else if (Notification.permission === 'granted') {
          // multiple calls will work
          self.subscribeUserToPush()
        }
      }
    }

    self.subscribeUserToPush = function() {
      navigator.serviceWorker.ready.then(function(registration) {
        const applicationServerKey = api.defaults.pushApplicationServerKey
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        }).then(function(subscription) {
          console.log('User is subscribed:', subscription);
          // send subscription to server
          const payload = {
            endpoint: subscription.endpoint,
            p256dh: btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('p256dh')))),
            auth: btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('auth'))))
          }
          api.post(`zpcard/subscribe-push-notifications/`, payload).then(response => {
            console.log(response)
            self.set('pushNotification', 'subscribed')
          })
        }).catch(function(error) {
          console.error('Failed to subscribe the user:', error);
        });
      })
    }

    self.initMattermost()

    // remove the old ids from localStorage which have an length of 13
    // ["ed058d770df84","f4606dc2b3464","0bcd7af07fba4"]
    for (const key of ['favorite_exhibitor', 'favorite_sponsor']) {
      let favs = self.getItem(key, [])
      for (const fav of favs) {
        if (fav.length <= 13) {  // alte id
          localStorage.removeItem(key)
          localStorage.removeItem(`${key.substring(9)}s`)
          break
        }
      }
    }

    // rename the old speaker_tool_marked key in localStorage to favorite_session
    let favSessions = self.getItem('speaker_tool_marked', [])
    if (favSessions.length) {
      self.setItem('favorite_session', favSessions)
      localStorage.removeItem('speaker_tool_marked')
    }
}